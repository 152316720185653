<template>
  <div>
    <div  v-if="loading" class="bgc-white bd bdrs-3 p-20 mB-20"> <loading/></div>
    <div v-else class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-20">
          <h2 class="c-grey-900 mB-20" style="display: inline-block">
            {{ $t("broadcast") }}
          </h2>
          <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
          <button class="btn btn-sm btn-success" @click="">
            <sui-icon name="add" /> {{ $t('addOffer') }}
          </button>
          <div class="row">
            <div id="dataTable_wrapper" class="col-sm-4 dataTables_wrapper">
              <div v-for="item in broadcasts"class="layer w-100 fxg-1 scrollable pos-r ps ps--active-y">
                <div @click="showBroadcast(item)">
                  <div class="email-list-item peers fxw-nw p-20 bdB bgcH-grey-100 cur-p">
                    <div class="peer mR-10">
                      <div class="checkbox checkbox-circle checkbox-info peers ai-c">
                        <input type="checkbox" id="inputCall1" name="inputCheckboxesCall" class="peer">
                        <label for="inputCall1" class="form-label peers peer-greed js-sb ai-c"></label>
                      </div>
                    </div>
                    <div class="peer peer-greed ov-h">
                      <div class="peers ai-c">
                        <div class="peer peer-greed">
                          <h6>{{ item.reciver_type_id == '1' ? getTranslation(item.reciver_type.ar_name , item.reciver_type.en_name) : item.client.full_name}}</h6>
                        </div>
                        <div class="peer">
                          <small>{{ timeAgo(item.created_at) }}</small>
                        </div>
                      </div>
                      <h5 class="fsz-def tt-c c-grey-900">{{ getTranslation(item.ar_title,item.en_title) }}</h5>
                      <span class="whs-nw w-100 ov-h tov-e d-b">{{ item.body }}</span>
                    </div>
                  </div>
                </div>
                <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                  <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                </div>
                <div class="ps__rail-y" style="top: 0px; height: 622px; right: 0px;">
                  <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 255px;"></div>
                </div>
              </div>
              <loading v-show="loading" />
            </div>
            <div v-if="broadcast" class="email-content-wrapper col-sm-8">
              <div class="peers ai-c jc-sb pX-40 pY-30">
                <div class="peers peer-greed">
                  <div class="peer mR-20">
                    <img src="@/assets/static/images/lady.png" class="bdrs-50p w-3r h-3r"  />
                  </div>
                  <div class="peer">
                    <small>{{ formatDate(broadcast.created_at) }}</small>
                    <h5 class="c-grey-900 mB-5">{{ $t('via') +': '+(broadcast.supervisor == null ? getTranslation(broadcast.sender_type.ar_name,broadcast.sender_type.en_name) : getTranslation(broadcast.supervisor.ar_name , broadcast.supervisor.en_name) ) }}</h5>
                    <span>{{ $t('to') +': '+(broadcast.client == null ? getTranslation(broadcast.reciver_type.ar_name,broadcast.reciver_type.en_name) : broadcast.client.full_name) }}</span>
                  </div>
                </div>
                <div class="peer">
                  <a href="" class="btn btn-danger bdrs-50p p-15 lh-0">
                    <i class="fa fa-reply"></i>
                  </a>
                </div>
              </div>
              <div class="bdT pX-40 pY-30">
                <h4>{{ getTranslation(broadcast.ar_title , broadcast.en_title) }}</h4>
                <p>{{ broadcast.body }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        loading: false,
        broadcasts: [],
        broadcast: null,
      }
    },
    created() {
      this.getbroadcasts();
    },
    methods: {
      formatDate(dateString) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}, ${day} ${year}`;
},
      showBroadcast(item) {
        this.broadcast = item;
        console.log('hi')
      },
      timeAgo(string) {

        const timestamp = new Date(string);
    
    // Adjust timestamp to Qatar time (UTC+3)
    timestamp.setHours(timestamp.getHours() + 3);

    const currentTime = new Date();
    const timeDiff = currentTime - timestamp;

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
        return years === 1 ? '1 year ago' : `${years} years ago`;
    } else if (months >= 11) {
        return '11 months ago';
    } else if (months >= 6) {
        return `${months} months ago`;
    } else if (months >= 2) {
        return `${months} months ago`;
    } else if (days > 0) {
        return days === 1 ? '1 day ago' : `${days} days ago`;
    } else if (hours > 0) {
        return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    } else if (minutes > 0) {
        return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    } else {
        return 'Just now';
    }
},
      getTranslation(ar, en) {
        return localStorage.getItem('lang') == 'ar' ? ar : en;
      },
      getbroadcasts: function() {
        this.loading = true;
        this.$http.get("/backend/broadcasts/get", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            key: this.key,
          },
        }).then(function(data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.broadcasts = data.body;
                if(this.broadcasts.length > 0) {
                  this.broadcast = this.broadcasts[0];
                }
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          });
      },
      error: function(message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function() {
        this.$toast.success(this.$t("success"), {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
</script>
<style scoped>
  #mainContent {
    text-align: right;
    direction: rtl;
  }
</style>